import { Box, Grid, Stack, Toolbar, Typography, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, Button, Pagination, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API } from '../../utils/Api';
import { CloseOutlined } from '@mui/icons-material';

const DamagedRawMaterials = () => {
    const token = localStorage.getItem('token');
    const outletData = JSON.parse(localStorage.getItem('outletData'));
    const [allRawMaterials, setAllRawMaterials] = useState([]); // Stores the entire list
    const [rawMaterials, setRawMaterials] = useState([]);       // Stores filtered results
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [remark, setRemark] = useState('');
    const [quantities, setQuantities] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);

    const fetchAllRawMaterials = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${API.BASE_URL}stock-transactions/getOutletCurrentStock`, {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    outletId: outletData?._id,
                    limit: 1000,
                },
            });
            setAllRawMaterials(response?.data?.data?.totalRecords || []);
            setCategories(response?.data?.data?.rawMaterialCategories || []);
        } catch (error) {
            toast.error('Failed to fetch raw materials.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllRawMaterials();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let filtered = [...allRawMaterials];

        if (selectedCategory) {
            filtered = filtered.filter(
                (item) => item?.rawMaterial?.mainCategory?._id === selectedCategory
            );
        }

        if (searchQuery.trim()) {
            filtered = filtered.filter((item) =>
                item?.rawMaterial?.name
                    ?.toLowerCase()
                    ?.includes(searchQuery.toLowerCase())
            );
        }
        setRawMaterials(filtered);
        setCurrentPage(1); // Reset pagination on filter change
    }, [allRawMaterials, selectedCategory, searchQuery]);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedMaterials = rawMaterials.slice(startIndex, endIndex);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    // const handleQuantityChange = (itemId, value) => {
    //     const parsedValue = value;
    //     setQuantities((prev) => {
    //         const updatedQuantities = { ...prev, [itemId]: parsedValue };

    //         if (parsedValue > 0) {
    //             if (!selectedItems.includes(itemId)) {
    //                 setSelectedItems([...selectedItems, itemId]);
    //             }
    //         } else {
    //             // Remove the item if quantity is 0 or less
    //             setSelectedItems(selectedItems.filter((id) => id !== itemId));
    //             delete updatedQuantities[itemId];
    //         }

    //         return updatedQuantities;
    //     });
    // };

    const handleQuantityChange = (itemId, inputValue) => {
        setQuantities((prev) => {
            const updatedQuantities = { ...prev, [itemId]: inputValue };
            const numericValue = parseFloat(inputValue);
            if (!isNaN(numericValue) && numericValue > 0) {
                if (!selectedItems.includes(itemId)) {
                    setSelectedItems([...selectedItems, itemId]);
                }
            } else {
                setSelectedItems(selectedItems.filter((id) => id !== itemId));
                if (!inputValue || isNaN(numericValue)) {
                    delete updatedQuantities[itemId];
                }
            }
            return updatedQuantities;
        });
    };

    const handleSubmit = async () => {
        if (!selectedItems.length) {
            toast.error('Please select at least one item by adding quantities.');
            return;
        }

        if (!remark.trim()) {
            toast.error('Please provide a reason for the transaction.');
            return;
        }

        const requestData = selectedItems.map((itemId) => {
            const item = allRawMaterials.find(
                (rawM) => rawM.rawMaterial._id === itemId
            );
            return {
                rawMaterialId: itemId,
                // currentStockQuantity: item?.currentStock,
                transferQuantity: Number(quantities[itemId]),
            };
        });

        try {
            const response = await axios.post(
                `${API.BASE_URL}stock-transactions/debitDamageStockFromOutlet`,
                {
                    outletId: outletData?._id,
                    outletStockTransferData: requestData,
                    remark,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response?.data) {
                toast.success('Damaged raw materials logged successfully.');
                setSelectedItems([]);
                setQuantities({});
                setRemark('');
                // Refresh raw materials
                fetchAllRawMaterials();
            } else {
                throw new Error('Submission failed.');
            }
        } catch (error) {
            toast.error(error?.response?.data?.error || 'Failed to log damaged raw materials.');
        }
    };

    const uniqueCategories = categories?.filter((category, index, self) => index === self.findIndex((c) => c?.mainCategory?._id === category?.mainCategory?._id));

    return (
        <Box>
            <Toolbar />
            <Grid container>
                <Grid item md={12}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            margin: '40px 0 20px 40px',
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}
                    >
                        Damaged Raw Materials
                    </Typography>
                </Grid>

                {/* Left side: Search, Filter, List of Materials */}
                <Grid
                    item
                    md={6}
                    sx={{ height: '85vh', overflowY: 'auto', padding: 3 }}
                >
                    {/* Search & Category Filter */}
                    <Grid
                        container
                        md={12}
                        justifyContent={'center'}
                        sx={{ marginTop: '10px' }}
                    >
                        <Grid item md={6}>
                            <TextField
                                fullWidth
                                sx={{
                                    backgroundColor: '#fff',
                                    marginTop: '5px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'darkblue',
                                        borderWidth: '2px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '10px',
                                        height: '56px',
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'darkblue',
                                        },
                                    },
                                    '& input': {
                                        height: '10px',
                                    },
                                }}
                                placeholder="Search for Raw Materials"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </Grid>

                        <Grid item md={3}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                sx={{
                                    backgroundColor: '#fff',
                                    marginTop: '5px',
                                    marginLeft: '5px',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'darkblue',
                                        borderWidth: '2px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '10px',
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'darkblue',
                                        },
                                    },
                                    '& input': {
                                        height: '10px',
                                    },
                                }}
                            >
                                <InputLabel id="category-select-label">
                                    Category
                                </InputLabel>
                                <Select
                                    labelId="category-select-label"
                                    id="category-select"
                                    value={selectedCategory}
                                    onChange={(e) =>
                                        setSelectedCategory(e.target.value)
                                    }
                                    label="Category"
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {uniqueCategories?.map((category) => (
                                        <MenuItem
                                            key={category?.mainCategory?._id}
                                            value={category?.mainCategory?._id}
                                        >
                                            {category?.mainCategory?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {/* Raw Materials List */}
                    {loading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh',
                            }}
                        >
                            <CircularProgress />
                            <Typography
                                sx={{ marginLeft: 2, fontSize: '16px' }}
                            >
                                Fetching raw materials...
                            </Typography>
                        </Box>
                    ) : (
                        <Grid
                            container
                            md={11}
                            spacing={3}
                            sx={{ margin: '0 auto' }}
                        >
                            {paginatedMaterials?.length > 0 ? (
                                paginatedMaterials.map((item) => {
                                    const itemId = item?.rawMaterial?._id;
                                    const isSelected = selectedItems.includes(itemId);
                                    const isOutOfStock = item?.currentStock <= 0;

                                    return (
                                        <Grid item xs={12} key={itemId}>
                                            <Box
                                                sx={{
                                                    padding: 2,
                                                    borderRadius: '12px',
                                                    border: isSelected
                                                        ? '3px solid #003049'
                                                        : '1px solid #e0e0e0',
                                                    backgroundColor: isSelected
                                                        ? '#f0f5f9'
                                                        : '#fff',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                        color: '#333',
                                                        flex: 1,
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    {item?.rawMaterial?.name}
                                                </Typography>
                                                <Typography sx={{ fontSize: '13px', fontWeight: '500', color: '#333', flex: 1 }}>{item?.currentStock?.toFixed(2)}{' '}{item?.rawMaterial?.unit}</Typography>
                                                {isOutOfStock ? (
                                                    <Typography sx={{ color: 'red', fontSize: '14px', fontWeight: 'bold', marginLeft: '10px' }}>Out of Stock</Typography>
                                                ) : (
                                                    <TextField
                                                        variant="outlined"
                                                        placeholder="Enter quantity"
                                                        value={quantities[itemId] || ''}
                                                        onChange={(e) => handleQuantityChange(itemId, e.target.value)}
                                                        sx={{
                                                            width: '150px',
                                                            borderRadius: '10px',
                                                            backgroundColor: '#fff',
                                                            marginTop: '5px',
                                                            '& .MuiOutlinedInput-root':
                                                            {
                                                                borderRadius: '10px',
                                                                '&.Mui-focused fieldset':
                                                                {
                                                                    borderColor:
                                                                        'darkblue',
                                                                },
                                                            },
                                                            '& input': {
                                                                height: '10px',
                                                            },
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        </Grid>
                                    );
                                })
                            ) : (
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: 3,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            color: '#666',
                                        }}
                                    >
                                        No raw materials found.
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    )}

                    {/* Pagination */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: 3,
                        }}
                    >
                        <Pagination
                            count={Math.ceil(rawMaterials.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            shape="rounded"
                            variant="outlined"
                        />
                    </Box>
                </Grid>

                {/* Right side: Selected Items & Reason Field */}
                <Grid
                    item
                    md={6}
                    sx={{ height: '85vh', overflowY: 'auto', padding: '10px 0 20px 0' }}
                >
                    {selectedItems?.length > 0 && (
                        <Box
                            sx={{
                                margin: 3,
                                padding: 2,
                                border: '1px solid #e0e0e0',
                                borderRadius: '8px',
                                backgroundColor: '#f9f9f9',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    marginBottom: 2,
                                    textAlign: 'center',
                                }}
                            >
                                Selected Items:
                            </Typography>
                            <Stack spacing={1} alignItems={'center'}>
                                {selectedItems.map((itemId) => {
                                    // Look up item from allRawMaterials
                                    const selectedItem = allRawMaterials.find(
                                        (i) => i?.rawMaterial?._id === itemId
                                    );
                                    // If we no longer find it, skip rendering
                                    if (!selectedItem) return null;

                                    return (
                                        <Box
                                            key={itemId}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                padding: 1.5,
                                                backgroundColor: '#003049',
                                                borderRadius: '8px',
                                                color: '#fff',
                                                width: '50%',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '15px',
                                                    fontWeight: '500',
                                                    flex: 1,
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                {selectedItem?.rawMaterial
                                                    ?.name}{' '}
                                                (
                                                {quantities[itemId]}{' '}
                                                {selectedItem?.rawMaterial?.unit}
                                                )
                                            </Typography>
                                            <IconButton
                                                onClick={() => {
                                                    setSelectedItems((prev) =>
                                                        prev.filter(
                                                            (id) => id !== itemId
                                                        )
                                                    );
                                                    setQuantities((prev) => {
                                                        const updated = {
                                                            ...prev,
                                                        };
                                                        delete updated[itemId];
                                                        return updated;
                                                    });
                                                }}
                                                sx={{
                                                    color: '#fff',
                                                    '&:hover': {
                                                        color: '#b0bec5',
                                                    },
                                                }}
                                            >
                                                <CloseOutlined />
                                            </IconButton>
                                        </Box>
                                    );
                                })}
                            </Stack>
                        </Box>
                    )}

                    <Grid container justifyContent={'center'}>
                        <Grid item md={10}>
                            <TextField
                                fullWidth
                                multiline
                                label="Reason for Raw Materials Damage"
                                variant="outlined"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                                rows={4}
                                sx={{ marginTop: 3 }}
                            />
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            marginTop: 3,
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            sx={{
                                padding: '10px 80px',
                                backgroundColor: '#003049',
                                borderRadius: '8px',
                                fontWeight: 'bold',
                                '&:hover': {
                                    backgroundColor: '#002433',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: '#b0bec5',
                                    color: '#ffffff',
                                },
                            }}
                        >
                            Confirm
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DamagedRawMaterials;
