import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid, Card, CardContent, Stack, Divider, Toolbar } from '@mui/material';
import { Person, Delete, FactCheck, Redeem } from '@mui/icons-material';
import axios from 'axios';
import { API } from '../../utils/Api';

const CustomerSupport = () => {
    const token = localStorage.getItem('token');
    const [mobileNumber, setMobileNumber] = useState('');
    const [customerData, setCustomerData] = useState(null);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (mobileNumber.length !== 10 || isNaN(mobileNumber)) {
            setError('Please enter a valid 10-digit mobile number.');
            return;
        }
        setError('');
        try {
            const response = await axios.get(`${API.BASE_URL}user/getCustomerDetails?mobile=${mobileNumber}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setCustomerData(response?.data);
        } catch (error) {
            console.error('Error fetching customer details:', error?.response?.data?.data?.message);
        }
    };

    return (
        <Box sx={{ p: 4, bgcolor: '#f4f6f8', minHeight: '100vh' }}>
            <Toolbar />
            <Typography
                variant="h4"
                sx={{
                    mt: 3,
                    mb: 1,
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: '#003049',
                    letterSpacing: '0.05rem',
                }}
            >
                Customer Complaint Resolution
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: 'text.secondary' }}>
                Enter the customer's mobile number to check their details and resolve complaints.
            </Typography>

            {/* Input Form */}
            <form
                onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '30px',
                }}
            >
                <TextField
                    label="Mobile Number"
                    variant="outlined"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    error={!!error}
                    helperText={error}
                    sx={{
                        mr: 2,
                        width: '300px',
                        bgcolor: '#ffffff',
                        borderRadius: 2,
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                    }}
                />
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{
                        width: '150px',
                        bgcolor: '#003049',
                        color: '#ffffff',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        '&:hover': { bgcolor: '#002437' },
                    }}
                >
                    Submit
                </Button>
            </form>

            {/* Customer Details */}
            {customerData && (
                <Grid container spacing={4}>
                    {/* Customer Details Section */}
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
                                borderRadius: 2,
                                transition: 'transform 0.2s',
                                '&:hover': { transform: 'scale(1.02)' },
                            }}
                        >
                            <CardContent>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Person color="primary" />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#003049' }}>
                                        Customer Details
                                    </Typography>
                                </Stack>
                                <Divider sx={{ my: 2 }} />
                                <Stack direction="row" spacing={4}>
                                    <Typography variant="body1">Name: {customerData?.customerDetails?.name}</Typography>
                                    <Typography variant="body1">Mobile: {customerData.customerDetails.mobile}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={4} sx={{ mt: 2 }}>
                                    <Typography variant="body1">Email: {customerData?.customerDetails?.email}</Typography>
                                    <Typography variant="body1">Joining Date: {new Date(customerData?.customerDetails?.joiningDate).toLocaleDateString()}</Typography>

                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* {customerData.deletedAccounts && customerData.deletedAccounts.length > 0 && (
                        <Grid item xs={12}>
                            <Card
                                sx={{
                                    boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
                                    borderRadius: 2,
                                    transition: 'transform 0.2s',
                                    '&:hover': { transform: 'scale(1.02)' },
                                }}
                            >
                                <CardContent>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <Delete color="error" />
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#d32f2f' }}>
                                            Deleted Accounts
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ my: 2 }} />
                                    {customerData.deletedAccounts.map((account, index) => (
                                        <Typography key={index} variant="body1">
                                            Account {index + 1} Created Date: {account.createdDate}
                                        </Typography>
                                    ))}
                                </CardContent>
                            </Card>
                        </Grid>
                    )} */}

                    <Grid item xs={12}>
                        <Card
                            sx={{
                                boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
                                borderRadius: 2,
                                transition: 'transform 0.2s',
                                '&:hover': { transform: 'scale(1.02)' },
                            }}
                        >
                            <CardContent>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <FactCheck color="primary" />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                                        Other Facts
                                    </Typography>
                                </Stack>
                                <Divider sx={{ my: 2 }} />
                                <Stack direction="row" spacing={4}>
                                    <Typography variant="body1">Total Orders: {customerData?.orderStats?.totalOrders}</Typography>
                                    <Typography variant="body1">Cancelled Orders: {customerData?.orderStats?.canceledOrders}</Typography>
                                    <Typography variant="body1">Referred Users: {customerData?.referrals?.referredCount || 0}</Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                        <Card
                            sx={{
                                boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
                                borderRadius: 2,
                                transition: 'transform 0.2s',
                                '&:hover': { transform: 'scale(1.02)' },
                            }}
                        >
                            <CardContent>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Redeem color="success" />
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#388e3c' }}>
                                        Coupon Details
                                    </Typography>
                                </Stack>
                                <Divider sx={{ my: 2 }} />
                                {customerData?.couponDetails?.joiningOffer?.usedDate && (
                                    <Typography variant="body1" sx={{ mb: 1 }}>
                                        Joining Offer Used on: {customerData?.couponDetails?.joiningOffer?.usedDate} at{' '}
                                        {customerData?.couponDetails?.joiningOffer?.outlet}
                                    </Typography>
                                )}
                                <Typography variant="body1" sx={{ mb: 1 }}>
                                    Referred Users:
                                </Typography>
                                <Stack direction="column" spacing={1}>
                                    {customerData?.referrals?.referredUsers?.map((coupon, index) => (
                                        <>
                                            <Typography key={index} variant="body1" textTransform={'capitalize'}>
                                                {index + 1}: {coupon?.name}
                                            </Typography>
                                            <Typography key={index} variant="body1">
                                                Joined On: {new Date(coupon?.joinedAt).toLocaleDateString()}
                                            </Typography>
                                        </>
                                    ))}
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            )}
        </Box>
    );
};

export default CustomerSupport;
