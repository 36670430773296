import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Stack } from '@mui/material';
import axios from 'axios';

const OtpModal = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mobileNumber?.length !== 10) {
      setError('Please enter a valid 10-digit mobile number.');
      return;
    }
    setError('');
    try {
      const response = await axios.get(`https://sms.zupzap.com/verification/get-otp`, {
        params: {
          mobile: mobileNumber,
        },
      });
      if (response?.data?.data?.record?.otp) {
        setOtp(response?.data?.data?.record?.otp);
        setError('');
      } else {
        setError('Failed to retrieve OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error fetching OTP:', error?.response?.data?.data?.message);
      setError(error?.response?.data?.data?.message || 'An error occurred while fetching the OTP. Please try again later.');
    }
  };

  const isValidMobileNumber = mobileNumber.length === 10 && !isNaN(mobileNumber);

  return (
    <Box
      sx={{
        width: 420,
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        p: 4,
        mx: 'auto',
        my: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #e0e0e0',
        bgcolor: 'white',
        overflow: 'hidden',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          mb: 1.5,
          fontWeight: 'bold',
          textAlign: 'center',
          color: '#003049',
          textTransform: 'uppercase',
          letterSpacing: '0.05rem',
        }}
      >
        OTP Verification
      </Typography>
      <Typography
        variant="body2"
        sx={{
          mb: 3,
          textAlign: 'center',
          color: 'text.secondary',
          fontSize: '0.95rem',
        }}
      >
        Enter the customer's mobile number to receive the active OTP.
      </Typography>
      <form
        onSubmit={handleSubmit}
        style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <TextField
          fullWidth
          label="Mobile Number"
          variant="outlined"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
          error={!!error}
          helperText={error}
          sx={{
            mb: 3,
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#003049',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#003049',
            },
            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#003049',
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          size="large"
          disabled={!isValidMobileNumber}
          sx={{
            mb: 2,
            textTransform: 'uppercase',
            borderRadius: 12,
            fontWeight: 'bold',
            fontSize: '0.9rem',
            backgroundColor: isValidMobileNumber ? '#003049' : '#e0e0e0',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: isValidMobileNumber ? '#002437' : '#e0e0e0',
            },
            '&.Mui-disabled': {
              backgroundColor: '#b0bec5',
              color: '#ffffff',
            },
          }}
        >
          Get OTP
        </Button>
      </form>
      {otp && (
        <Stack
          direction="column"
          alignItems="center"
          spacing={1}
          sx={{
            mt: 3,
            p: 2,
            bgcolor: '#f9fafb',
            borderRadius: 2,
            width: '100%',
            textAlign: 'center',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: '#37474f',
              fontSize: '0.95rem',
            }}
          >
            Active OTP:
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              color: '#388e3c',
              letterSpacing: '0.1rem',
            }}
          >
            {otp}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default OtpModal;

// import React, { useEffect, useRef, useState } from 'react';
// import logo from '../assets/images/xpanceLabel.png';
// import PrimaryButton from './PrimaryButton';
// import { toast } from 'react-toastify';

// const pageStyle = `
//   @page {
//     size: 50mm 75mm;
//     margin: 0;
//   }

//   body {
//     text-transform: uppercase;
//   }

//   @media all {
//     .pageBreak {
//       display: none;
//     }
//   }

//   @media print {
//     body {
//       font-family: "Courier Prime", monospace;
//     }
//     .bodyBox{
//       padding: 10px !important;
//       page-break-before: always; 
//     }
//     .orderNoAndTime{
//       display: flex;
//       justify-content: space-between;
//       align-items: baseline;
//       padding-bottom: 4px;
//     }
//     .orderNo{
//       font-weight: 800;
//       margin: 0;
//       font-size: 20px;
//     }
//     .readyTime{
//       font-weight: 800;
//       margin: 0;
//       font-size: 20px;
//       text-align: right;
//     }
//     .productName{
//       font-size: 14px;
//       font-weight: 800;
//       margin: 0;
//     }
//     ul {
//       margin: 0;
//       padding: 5px;
//     }
//     li {
//       font-size: 12px;
//       margin: 0;
//     }
//     .pageBreak {
//       page-break-before: always;
//     }
//   }
// `;

// const ContentToPrint = React.forwardRef(
//   ({ productName, addons, orderNo, orderSequence, readyTime, ordersType, takeAway }, ref) => {
//     return (
//       <div className='bodyBox' ref={ref}>
//         <div>
//           <div className='orderNoAndTime'>
//             <p className='orderNo'>#{orderSequence}{orderNo}</p>
//             <p className='readyTime'>
//               ({takeAway === 'TAKE-AWAY' ? 'T' : ordersType === 'PICKUP' ? 'P' : ordersType === 'DELIVERY' ? 'D' : ''})
//             </p>
//             {/* <p className='readyTime'>{readyTime}</p> */}
//           </div>
//           <p className='productName'>{productName}</p>
//           {addons && addons?.length > 0 && (
//             <ul>
//               {addons?.map((addon, index) => (
//                 <li key={index}> {addon?.selectedValue}</li>
//               ))}
//             </ul>
//           )}
//           <p className='readyTime'>{readyTime}</p>
//         </div>
//       </div>
//     );
//   }
// );

// const LabelPrinter = ({ orders, orderDetail }) => {
//   const contentRefs = useRef([]);
//   const [dataArr, setDataArr] = useState([]);

//   // console.log('Received orders:', orders);

//   // useEffect(() => {
//   //   if (orders) {
//   //     console.log('Received orders:', orders);
//   //     const updatedDataArr = [];
//   //     orders?.orderItems.forEach((orderItem) => {
//   //       for (let i = 0; i < orderItem.quantity; i++) {
//   //         updatedDataArr.push(orderItem);
//   //       }
//   //     });
//   //     setDataArr(updatedDataArr);
//   //   }
//   // }, [orders]);

//   useEffect(() => {
//     if (orders) {
//       // console.log('Received orders:', orders);
//       const sortedOrderItems = orders.orderItems.sort((a, b) => {
//         if (a.product.classType === 'BEVERAGE' && b.product.classType !== 'BEVERAGE') {
//           return -1;
//         }
//         if (a.product.classType !== 'BEVERAGE' && b.product.classType === 'BEVERAGE') {
//           return 1;
//         }
//         return 0;
//       });
  
//       const updatedDataArr = [];
//       sortedOrderItems.forEach((orderItem) => {
//         for (let i = 0; i < orderItem.quantity; i++) {
//           updatedDataArr.push(orderItem);
//         }
//       });
//       setDataArr(updatedDataArr);
//     }
//   }, [orders]);
  
//   const printContent = () => {
//     // toast.success("KOT printed!", { autoClose: 3000 });
//     const iframe = document.createElement('iframe');
//     iframe.style.display = 'none';
//     document.body.appendChild(iframe);
//     const printDocument = iframe.contentWindow || iframe.contentDocument;
//     const doc = printDocument.document || printDocument;
//     doc.open();
//     doc.write('<html><head><style>');
//     doc.write(pageStyle);
//     doc.write('</style></head><body>');
//     dataArr.forEach((order, index) => {
//       const content = contentRefs.current[index];
//       doc.write(content.outerHTML);
//       if (index < dataArr.length - 1) {
//         doc.write('<div class="pageBreak"></div>');
//       }
//     });
//     doc.write('</body></html>');
//     doc.close();
//     iframe.focus();
//     iframe.contentWindow.print();
//   };

//   return (
//     <>
//       <div style={{ display: 'none' }}>
//         {dataArr.map((order, index) => (
//           <ContentToPrint
//             key={index}
//             ref={(ref) => (contentRefs.current[index] = ref)}
//             productName={order.product.name}
//             addons={order.addons}
//             orderNo={orderDetail.order_no}
//             orderSequence={orderDetail.order_sequence}
//             readyTime={orderDetail.readyTime}
//             orderSerialNo={order.orderSerialNo}
//             ordersType={orderDetail.ordersType}
//             takeAway={orderDetail.pickupOption}
//           />
//         ))}
//       </div>
//       <PrimaryButton onClick={printContent} disabledTitle={'Printing'} title={'Print Label'} style={{ borderRadius: '30px' }} />
//     </>
//   );
// };

// export default LabelPrinter;
