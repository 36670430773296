import { Box, Grid, Stack, Toolbar, Typography, Switch, Dialog, DialogContent, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, Button, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Images from '../../utils/Images';
import axios from 'axios';
import { API } from '../../utils/Api';
import { useSelector } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { CloudDownloadOutlined } from '@mui/icons-material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function Reports() {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");
  const [rawMaterials, setRawMaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.login.user);
  const [searchQuery, setSearchQuery] = useState('');
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('outletData')));
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedMaterial, setSelectedMaterial] = useState('');
  const [storageLocations, setStorageLocations] = useState([]);
  const [selectedStorageLocations, setSelectedStorageLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  const [selectedStartDate, setSelectedStartDate] = useState(formattedDate);
  const [selectedEndDate, setSelectedEndDate] = useState(formattedDate);

  const getRawMaterialsList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API.BASE_URL}stock-transactions/getOutletCurrentStock?outletId=${outletData._id}&limit=100`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          mainCategory: selectedCategory,
          searchRawMaterial: searchQuery
        }
      }
      );
      const totalRecords = response?.data?.data?.totalRecords;
      console.log('totalRecords', response?.data?.data);
      setCategories(response?.data?.data?.rawMaterialCategories);
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = (rawMaterialId, value) => {
    setTransferQuantities((prev) => ({
      ...prev,
      [rawMaterialId]: value
    }));
  };

  const [transferQuantities, setTransferQuantities] = useState({});

  const handleDebitStock = async (rawMaterialId, currentStockQuantity) => {
    const transferQuantity = transferQuantities[rawMaterialId] || 0;
    if (transferQuantity <= 0 || transferQuantity > currentStockQuantity) {
      toast.error("Invalid transfer quantity", {
        autoClose: 3000,
      });
      return;
    }
    try {
      const response = await axios.post(`${API.BASE_URL}stock-transactions/debitStock`,
        {
          "outletId": outletData._id,
          "outletStockTransferData": [
            {
              "rawMaterialId": rawMaterialId,
              "currentStockQuantity": currentStockQuantity,
              "transferQuantity": transferQuantity
            }
          ]
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      if (response?.data?.status === true) {
        toast.success("Stock updated successful!", {
          autoClose: 3000,
        });
        // getRawMaterialsList();
        setTransferQuantities({});
      }
    } catch (error) {
      console.error('Error:', error?.response?.data?.data?.message);
      let errorMessage = error?.response?.data?.data?.message;
      toast.error(errorMessage, {
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getRawMaterialsList();
  }, []);

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);
    setCurrentPage(1);
    // console.log('Selected Category ID:', categoryId);
  };

  const handleStorageLocationChange = (event) => {
    const storageId = event.target.value;
    setSelectedStorageLocations(storageId);
    setCurrentPage(1);
    console.log('storageId', storageId);

  };

  const handleMaterialChange = (event) => {
    const rawMaterial = event.target.value;
    setSelectedMaterial(rawMaterial);
    // console.log('Selected Raw material ID:', rawMaterial);
    setCurrentPage(1);
  };

  const handleSearch = (value) => {
    setSearchQuery(String(value).trim());
  };

  const handleDateChange = (date) => {
    setSelectedStartDate(date);
    // console.log('date', date);
    setCurrentPage(1);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    // console.log('date', date);
    setCurrentPage(1);
  };

  const getStockTransactions = async () => {
    setLoading(true);
    try {
      const params = {
        fromDate: selectedStartDate,
        toDate: selectedEndDate,
      };
      if (selectedCategory) {
        params.mainCategory = selectedCategory;
      }
      if (selectedMaterial) {
        params.rawMaterialId = selectedMaterial;
      }
      if (selectedStorageLocations) {
        params.storageLocation = selectedStorageLocations;
      }
      const response = await axios.get(`${API.BASE_URL}stock-transactions/getOutletStockTransactions?outletId=${outletData?._id}&limit=1000`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params
      }
      );
      const totalRecords = response?.data?.data?.outletStockTransactionRecords;
      // console.log('totalRecords', response?.data?.data);
      // setCategories(response?.data?.data?.rawMaterialCategories);
      if (typeof totalRecords === 'string' && totalRecords.includes('No Records')) {
        setRawMaterials([]);
      } else {
        setRawMaterials(totalRecords);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const uniqueCategories = categories?.filter(
    (category, index, self) =>
      index === self.findIndex((c) => c?.mainCategory?._id === category?.mainCategory?._id)
  );

  {
    rawMaterials?.map((rawMaterials) => {
      return (
        <MenuItem key={rawMaterials?.rawMaterial?._id} value={rawMaterials?.rawMaterial?._id}>
          <span style={{ textTransform: 'capitalize' }}>{rawMaterials?.rawMaterial?.name}</span>
        </MenuItem>
      );
    })
  }

  const uniqueRawMaterials = rawMaterials?.filter(
    (material, index, self) =>
      index === self.findIndex((m) => m?.rawMaterial?._id === material?.rawMaterial?._id)
  );

  const handlePageChange = (event, page) => setCurrentPage(page);

  const paginatedMaterials = rawMaterials.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(rawMaterials.length / itemsPerPage);

  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Title and outlet details
    const title = 'Stock Transactions Reports';
    const textWidth = doc.getTextWidth(title);
    const titleX = (pageWidth - textWidth) / 2;
    doc.setFontSize(16);
    doc.text(title, titleX, 20);

    doc.setFontSize(10);
    const outletName = outletData?.name || 'Unknown Outlet';
    const outletAddress = outletData?.address || 'No Address Available';
    const outletContact = `${outletData?.email || 'No Email'}, ${outletData?.phone || 'No Phone Info'}`;
    doc.text(`Outlet: ${outletName}`, 14, 30);
    doc.text(`Address: ${outletAddress}`, 14, 40);
    doc.text(`Contact: ${outletContact}`, 14, 50);
    doc.setFontSize(12);
    const startY = 60;

    const columns = [
      { header: '#', dataKey: 'itemNo' },
      { header: 'Product Name', dataKey: 'productName' },
      { header: 'Category', dataKey: 'category' },
      { header: 'Sub Category', dataKey: 'subcategory' },
      { header: 'Storage Location', dataKey: 'storageLocation' },
      { header: 'Stock Qty', dataKey: 'stockQuantity' },
      { header: 'Unit', dataKey: 'unit' },
      { header: 'Type', dataKey: 'type' },
      { header: 'Qty', dataKey: 'quantity' },
    ];

    const sortedMaterials = rawMaterials.sort((a, b) => {
      const categoryA = a.rawMaterial.mainCategory.name.toLowerCase();
      const categoryB = b.rawMaterial.mainCategory.name.toLowerCase();
      const subcategoryA = a.rawMaterial.subCategory.name.toLowerCase();
      const subcategoryB = b.rawMaterial.subCategory.name.toLowerCase();

      if (categoryA < categoryB) return -1;
      if (categoryA > categoryB) return 1;
      if (subcategoryA < subcategoryB) return -1;
      if (subcategoryA > subcategoryB) return 1;
      return 0;
    });

    const rows = sortedMaterials.map((material, index) => ({
      itemNo: index + 1,
      productName: material?.rawMaterial?.name,
      category: material?.rawMaterial?.mainCategory?.name,
      subcategory: material?.rawMaterial?.subCategory?.name,
      storageLocation: material?.storageLocationDetails?.name,
      stockQuantity: parseFloat(material?.currentStock?.toFixed(2)),
      unit: material?.rawMaterial?.unit,
      type: material?.type,
      quantity: material?.quantity?.toFixed(2),
    }));

    doc.autoTable({
      columns,
      body: rows,
      startY: startY,
      theme: 'grid',
      styles: { halign: 'center' },
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 40 },
        2: { cellWidth: 30 },
        3: { cellWidth: 30 },
        4: { cellWidth: 20 },
        5: { cellWidth: 20 },
        6: { cellWidth: 20 },
        7: { cellWidth: 20 },
        8: { cellWidth: 20 },
      },
    });
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    doc.save(`${formattedDate}-Raw-Material-Inventory.pdf`);
  };

  const getStorageLocation = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}storage-location`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const locations = response?.data?.data?.storageLocations;
      setStorageLocations(locations);
    } catch (error) {
      if (error.response && error.response?.data?.status === false) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    getStorageLocation();
  }, [])

  return (
    <>
      <Box className="dashBoard">
        <Toolbar />
        <Grid container justifyContent={'center'}>
          <Grid item md={11} flexDirection={'column'}>
            <Grid container md={12} justifyContent={'space-evenly'} sx={{ marginTop: '35px' }}>
              <Grid item md={12}>
                <Typography sx={{ fontSize: '16px', margin: '16px 0 30px 40px', textAlign: 'center' }}>Stock Transactions</Typography>
              </Grid>
              <Grid item md={2}>
                <label style={{ paddingLeft: '10px' }}>Start Date</label>
                <DatePicker
                  selected={selectedStartDate}
                  onChange={(date) => handleDateChange(format(date, 'yyyy-MM-dd'))}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select Date"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  customInput={
                    <FormControl
                      fullWidth
                      sx={{
                        backgroundColor: '#fff',
                        marginTop: '5px',
                        marginLeft: '5px',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#cecece',
                          borderWidth: '1px',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '&.Mui-focused fieldset': {
                            borderColor: '#cecece',
                          },
                        },
                        '& input': {
                          height: '40px',
                          width: '80%'
                        },
                      }}
                    >
                      <input
                        className="form-control"
                        placeholder='Select Start Date'
                        value={selectedStartDate ? format(new Date(selectedStartDate), 'yyyy-MM-dd') : ''}
                        style={{ height: '56px', borderRadius: '10px', paddingLeft: '10px' }}
                        readOnly
                      />
                    </FormControl>
                  }
                />
              </Grid>
              <Grid item md={2}>
                <label style={{ paddingLeft: '10px' }}>End Date</label>
                <DatePicker
                  selected={selectedEndDate}
                  onChange={(date) => handleEndDateChange(format(date, 'yyyy-MM-dd'))}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select End Date"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  customInput={
                    <FormControl
                      fullWidth
                      sx={{
                        backgroundColor: '#fff',
                        marginTop: '5px',
                        marginLeft: '5px',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#cecece',
                          borderWidth: '2px',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '&.Mui-focused fieldset': {
                            borderColor: '#cecece',
                          },
                        },
                        '& input': {
                          height: '40px',
                          width: '80%'
                        },
                      }}
                    >
                      <input
                        className="form-control"
                        placeholder='Select End Date'
                        value={selectedEndDate ? format(new Date(selectedEndDate), 'yyyy-MM-dd') : ''}
                        style={{ height: '56px', borderRadius: '10px', paddingLeft: '10px' }}
                        readOnly
                      />
                    </FormControl>
                  }
                />
              </Grid>
              <Grid item md={2}>
                <label style={{ paddingLeft: '10px' }}>Storage Location</label>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{
                    backgroundColor: '#fff',
                    marginTop: '5px',
                    marginLeft: '5px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#cecece',
                      borderWidth: '2px',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      '&.Mui-focused fieldset': {
                        borderColor: '#cecece',
                      },
                    },
                    '& input': {
                      height: '40px',
                      width: '80%'
                    },
                  }}
                  style={{ height: '56px', width: '80%' }}
                >
                  <Select
                    labelId="storage-select-label"
                    id="storage-select"
                    value={selectedStorageLocations}
                    onChange={handleStorageLocationChange}
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {storageLocations?.map((location) => {
                      return (
                        <MenuItem key={location?._id} value={location?._id}>
                          {location?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {/* {uniqueRawMaterials?.length > 0 && ( */}
              <Grid item md={2}>
                <label style={{ paddingLeft: '10px' }}>Category</label>
                <FormControl
                  fullWidth
                  variant="outlined"
                  sx={{
                    backgroundColor: '#fff',
                    marginTop: '5px',
                    marginLeft: '5px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#cecece',
                      borderWidth: '2px',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      '&.Mui-focused fieldset': {
                        borderColor: '#cecece',
                      },
                    },
                    '& input': {
                      height: '40px',
                      width: '80%'
                    },
                  }}
                  style={{ height: '56px', width: '80%' }}
                >
                  {/* <InputLabel id="category-select-label">Category</InputLabel> */}
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  // label="Category"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {uniqueCategories?.map((category) => {
                      return (
                        <MenuItem key={category?.mainCategory?._id} value={category?.mainCategory?._id}>
                          {category?.mainCategory?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              {/* )} */}
              {uniqueRawMaterials?.length > 0 && (
                <Grid item md={2}>
                  <label style={{ paddingLeft: '10px' }}>Raw Material</label>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    sx={{
                      backgroundColor: '#fff',
                      marginTop: '5px',
                      marginLeft: '5px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#cecece',
                        borderWidth: '2px',
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '10px',
                        '&.Mui-focused fieldset': {
                          borderColor: '#cecece',
                        },
                      },
                      '& input': {
                        height: '40px',
                        width: '80%'
                      },
                    }}
                    style={{ height: '56px', width: '80%' }}
                  >
                    {/* <InputLabel id="material-select-label">Raw Material</InputLabel> */}
                    <Select
                      labelId="material-select-label"
                      id="material-select"
                      value={selectedMaterial}
                      onChange={handleMaterialChange}
                    // label="Category"
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      {uniqueRawMaterials?.map((material) => {
                        return (
                          <MenuItem key={material?.rawMaterial?._id} value={material?.rawMaterial?._id}>
                            <span style={{ textTransform: 'capitalize' }}>{material?.rawMaterial?.name}</span>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item md={2} textAlign="center" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25px' }}>
                <Button
                  variant="contained"
                  onClick={getStockTransactions}
                  sx={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    textTransform: 'none',
                    backgroundColor: '#003049',
                    '&:hover': {
                      backgroundColor: 'darkcyan',
                      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                >
                  Get Reports
                </Button>
              </Grid>
              {paginatedMaterials.length > 0 && (
                <Grid item md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25px' }}>
                  <CloudDownloadOutlined onClick={generatePDF} sx={{ fontSize: '45px', cursor: 'pointer', color: '#003049' }} />
                </Grid>
              )}
            </Grid>
            {loading ? (
              <Box sx={{ height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
                <Typography sx={{ paddingLeft: '10px' }}>Fetching Raw Materials</Typography>
              </Box>
            ) : (
              <>
                <>
                  {paginatedMaterials?.length === 0 ? (
                    <Typography sx={{ textAlign: 'center', padding: '40px', fontSize: '16px' }}>
                      No Stock Transactions to display. Please select a Category and a Date Range then click "Get Reports".
                    </Typography>
                  ) : (
                    <Grid container md={userRole !== 'CASHIER' ? 11 : 11} sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', borderRadius: '8px', padding: '0px 0px 0px 0px', margin: '21px 0px 25px 10px' }}>
                      <Grid item md={2} sx={{ padding: '20px 10px 20px 10px', borderRight: '1px solid grey' }}>
                        <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Date & Time</Typography>
                      </Grid>
                      <Grid item md={4} sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey' }}>
                        <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Raw Material Details</Typography>
                      </Grid>
                      <Grid item md={2} sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey' }}>
                        <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Storage Location</Typography>
                      </Grid>
                      <Grid item md={2} sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey' }}>
                        <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Type</Typography>
                      </Grid>
                      <Grid item md={2} sx={{ padding: '20px 0px 20px 0px' }}>
                        <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>QTY.</Typography>
                      </Grid>
                      <>
                        {paginatedMaterials?.length === 0 ? (
                          <Typography sx={{ textAlign: 'center', padding: '20px', fontSize: '16px' }}>
                            No matching products found.
                          </Typography>
                        ) : (
                          paginatedMaterials?.map((material, index) => {
                            const isEvenRow = index % 2 === 0;
                            const backgroundColor = isEvenRow ? '#EDF9FF' : '#fff';
                            return (
                              <React.Fragment key={index}>
                                <Grid item md={2} sx={{ position: 'relative', backgroundColor, padding: '20px 10px 20px 10px', borderRight: '1px solid grey', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                  <Typography sx={{ fontSize: '13px', fontWeight: '500' }}>{new Date(material?.date).toISOString().split('T')[0]}</Typography>
                                  <Typography sx={{ fontSize: '13px', fontWeight: '500' }}>{new Date(material?.date).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</Typography>
                                </Grid>
                                <Grid item md={4} sx={{ position: 'relative', backgroundColor, padding: '20px 10px 20px 20px', borderRight: '1px solid grey' }}>
                                  <Typography sx={{ fontSize: '16px', fontWeight: 'bold', textTransform: 'capitalize' }}>
                                    {material?.rawMaterial?.name}
                                  </Typography>
                                  <Typography sx={{ fontSize: '13px', textTransform: 'capitalize' }}>
                                    {material?.rawMaterial?.mainCategory?.name} &gt; {material?.rawMaterial?.subCategory?.name}
                                  </Typography>
                                  <Typography sx={{ fontSize: '11px', textTransform: 'capitalize', fontStyle: 'italic' }}>
                                    {material?.remark}
                                  </Typography>
                                </Grid>
                                <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center', borderRight: '1px solid grey', alignItems: 'center', textAlign: 'center' }}>
                                  <Typography sx={{ fontSize: '15px' }}>
                                    {material?.storageLocationDetails? `${material?.storageLocationDetails?.name},${material?.storageLocationDetails?.city}, ${material?.storageLocationDetails?.state}`: '----'}
                                  </Typography>
                                </Grid>
                                <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center', borderRight: '1px solid grey', alignItems: 'center' }}>
                                  <Typography sx={{ fontSize: '15px', }}>{material?.type}</Typography>
                                </Grid>
                                <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <Typography sx={{ fontSize: '15px', }}>{material?.quantity.toFixed(2)}{material?.rawMaterial?.unit}</Typography>
                                </Grid>
                              </React.Fragment>
                            );
                          })
                        )}
                      </>
                    </Grid>
                  )}
                </>
                {paginatedMaterials.length > 0 && (
                  <Grid container justifyContent="center" sx={{ padding: '20px 0' }}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Reports;